<template>
  <div
    :class="[
      'min-h-[100svh]',
      { 'bg-gray-200 backdrop-blur-lg': isDataLoading },
    ]"
  >
    <div
      v-if="isDataLoading"
      class="min-h-[100svh] flex items-center justify-center"
    >
      <LoadingData />
    </div>
    <div v-else class="min-h-[100svh] text-white backdrop-blur-lg pb-4">
      <div class="space-y-1 md:space-y-2 lg:space-y-3">
        <div class="relative flex justify-center items-center py-2">
          <img
            :src="getCurrentRadio?.radio_cover"
            :alt="getCurrentRadio?.current_track.artist_name"
            class="max-h-[5rem] sm:max-h-max sm:max-w-[12rem] lg:max-h-[9rem] xl:max-h-[8rem] 2xl:max-h-[6rem] aspect-auto rounded-lg object-center"
          />
          <button
            class="absolute py-1 md:py-2 px-3 bg-white rounded-lg top-3 md:top-4 right-2 md:right-4 shadow-md flex items-center gap-x-1"
            @click.prevent="shouldDisplayQrCode = true"
          >
            <InstallIcon class="w-5 h-5 md:w-6 md:h-6 stroke-black" />
            <span
              class="text-black text-xl inline-block h-8 bg-gray-800 w-[2px] rotate-[25deg]"
            ></span>
            <ShareIcon class="w-5 h-5 md:w-6 md:h-6 stroke-black" />
          </button>
          <button
            v-if="getCurrentRadio?.menu.length > 0"
            class="absolute py-2 px-3 bg-white rounded-lg top-4 left-4 shadow-md"
            @click.prevent="shouldDisplayMenus = true"
          >
            <MenuIcon class="h-7 w-7 stroke-black" />
          </button>
          <div v-else></div>
        </div>
        <div class="flex justify-center">
          <img
            :src="
              getCurrentRadio.current_track.cover
                ? getCurrentRadio.current_track.cover
                : getCurrentRadio.radio_pochette
                ? getCurrentRadio.radio_pochette
                : getCurrentRadio.radio_cover
            "
            :alt="getCurrentRadio?.current_track.album_title"
            class="rounded-lg h-52 max-w-[80%] md:h-96 lg:h-[15rem] object-center mx-auto lg:max-w-[60%]"
          />
        </div>
        <div class="text-center mt-2">
          <h1 class="uppercase font-bold text-lg">
            {{ getCurrentRadio?.current_track.artist_name }}
          </h1>
          <h2 class="text-md font-normal">
            {{
              getCurrentRadio?.current_track.title
                ? getCurrentRadio?.current_track.title
                : getCurrentRadio?.current_track.album_title
            }}
          </h2>
          <div class="flex justify-center items-center mt-2">
            <div
              class="rounded-full flex justify-center items-center bg-black h-16 w-16 cursor-pointer"
            >
              <PlayAndPause
                class="h-11 w-11"
                :isPlaying="isPlaying"
                @click.prevent="toggleAudio"
              />
            </div>
            <audio ref="audioPlayer" hidden>
              <source :src="streamingLink" />
              Your browser does not support the audio element.
            </audio>
          </div>
        </div>
        <div v-if="publicity.length" class="px-4 py-3 flex justify-center">
          <ThePublicity class="w-full lg:max-w-[80%]" :publicity="publicity" />
        </div>
      </div>
      <footer
        :class="['w-full', publicity.length ? 'mt-10' : 'absolute bottom-4']"
      >
        <div class="px-4">
          <template v-if="false">
            <h3 class="font-bold">Derniers titres diffusés</h3>
            <div v-if="histories.length" class="md:flex">
              <HistoryCard
                v-for="(history, index) in histories.slice(
                  histories.length - 1 - numberOfHistoryToDisplay,
                  histories.length - 1
                )"
                :key="history.title"
                :class="[
                  'shadow-none mt-3 px-4',
                  {
                    'rounded-l-lg': index === 0,
                    'rounded-r-lg': index + 1 === numberOfHistoryToDisplay,
                  },
                ]"
                :cover="history.cover ?? ''"
                :title="history.title ?? ''"
                :artist="history.artist_name"
                :album="history.album_title ?? ''"
              />
            </div>
            <div v-else class="w-full text-sm font-medium">
              Pas de titres diffusé
            </div>
          </template>
          <div>
            <div class="flex justify-between">
              <button
                v-if="getCurrentRadio.is_display_pub_volume"
                class="py-2 px-3 bg-white rounded-lg shadow-md text-black text-xs uppercase flex items-center gap-x-1"
                @click.prevent="shouldDisplayVolume = true"
              >
                <template
                  v-if="
                    audioPlayer?.volume !== undefined &&
                    audioPlayer?.volume !== null
                  "
                >
                  <VolumeUpIcon
                    class="h-6 w-6 stroke-black"
                    v-if="audioPlayer.volume >= 0.8"
                  />
                  <VolumeDownIcon
                    class="h-6 w-6 stroke-black"
                    v-if="audioPlayer.volume > 0 && audioPlayer.volume < 0.8"
                  />
                  <NoSoundIcon
                    class="h-6 w-6 stroke-black"
                    v-if="audioPlayer.volume === 0"
                  />
                </template>
                <VolumeUpIcon v-else class="h-6 w-6 stroke-black" />
              </button>
              <div v-else></div>
              <button
                v-if="getCurrentRadio.is_display_recent_title_bar"
                class="uppercase bg-white text-black text-xs px-4 py-2.5 rounded-lg flex items-center justify-center gap-x-1 shadow"
                @click.prevent="shouldDisplayRecentTitles = true"
              >
                <ClockIcon class="stroke-black h-5 w-5" />
                <span>Titres diffusés</span>
              </button>
              <div v-else></div>
              <a
                class="bg-white px-2.5 py-1 rounded-lg flex items-center shadow-md"
                :href="getCurrentRadio?.url_site"
                target="_blank"
                v-if="getCurrentRadio.url_site"
              >
                <WebsiteIcon class="h-7 w-7 stroke-black" />
              </a>
              <div v-else></div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
  <Modal
    :shouldDisplay="
      shouldDisplayQrCode ||
      shouldDisplayMenus ||
      shouldDisplayRecentTitles ||
      shouldDisplayVolume
    "
    @close="closeModal"
  >
    <TheQrCode
      v-if="shouldDisplayQrCode"
      :url="qrCodeUrl"
      :name="radioStore.radioName"
    />
    <TheMenu v-if="shouldDisplayMenus" :menus="getCurrentRadio?.menu ?? []" />
    <RecentTitles v-if="shouldDisplayRecentTitles" :histories="histories" />
    <div
      v-if="shouldDisplayVolume"
      class="py-3 gap-y-4 flex flex-col justify-center items-center"
    >
      <h2 class="text-xl font-bold">Volume</h2>
      <TheVolume :volume="audioPlayer?.volume ?? 0.5" @change="changeVolume" />
    </div>
  </Modal>
</template>
<script setup lang="ts">
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import LoadingData from "@/components/loader/LoadingData.vue";
import { useRadioStore } from "@/store/radioStore";
import {
  CurrentTrack,
  Publicity as PublicityType,
  RadioMetaData,
} from "./type";
import ShareIcon from "@/assets/ShareIcon.vue";
import MenuIcon from "@/assets/MenuIcon.vue";
import ClockIcon from "@/assets/ClockIcon.vue";
import WebsiteIcon from "@/assets/WebsiteIcon.vue";
import InstallIcon from "@/assets/InstallIcon.vue";
import PlayAndPause from "@/assets/PlayAndPause.vue";
import HistoryCard from "@/components/player/HistoryCard.vue";
import TheQrCode from "@/components/player/TheQrCode.vue";
import Modal from "@/components/modal/Modal.vue";
import TheMenu from "@/components/player/TheMenu.vue";
import ThePublicity from "@/components/player/ThePublicity.vue";
import RecentTitles from "@/components/player/RecentTitles.vue";
import { buildManifest, setFavIcon, setManifestHref } from "@/utils/manifest";
import { manageBluetoothInformation } from "@/utils/bluetooth";
import { BASE_APP_URL } from "@/utils/env";
import { getColorImg } from "@/utils/image";
import { fetchRadioMetaData } from "@/infra";
import {
  BeforeInstallPromptEvent,
  deferredPrompt,
  isInstallationSupported,
  onAfterInstall,
} from "@/utils/installation";
import TheVolume from "@/components/player/TheVolume.vue";
import VolumeUpIcon from "@/assets/VolumeUpIcon.vue";
import VolumeDownIcon from "@/assets/VolumeDownIcon.vue";
import NoSoundIcon from "@/assets/NoSoundIcon.vue";

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }
}

const isDataLoading = ref(true);
const currentRadio = ref<RadioMetaData>({} as RadioMetaData);
const histories = ref<CurrentTrack[]>([]);
const publicity = ref<PublicityType[]>([]);
const streamingLink = ref("");
const cover = ref<string>("");

const setBluetoothInfo = () => {
  manageBluetoothInformation({
    title: currentRadio.value?.current_track.title,
    album: currentRadio.value?.current_track.album_title,
    artist: currentRadio.value?.current_track.artist_name,
    artwork: [
      {
        src: currentRadio.value?.radio_cover,
      },
    ],
  });
};

const getRadioMetaData = async (radioName: string) => {
  radioStore.radioErrMsg = "";
  const radioMetaData = await fetchRadioMetaData(radioName);
  if (typeof radioMetaData === "string") {
    radioStore.radioErrMsg = radioMetaData;
    return;
  }

  currentRadio.value = radioMetaData;

  if (!radioMetaData.is_api_available) {
    radioStore.radioErrMsg =
      "Impossible de récupérer les données, veuillez verifier les informations de votre radio";
  }

  if (!radioMetaData || Object.keys(radioMetaData).length === 0) {
    radioStore.radioErrMsg =
      "Impossible de récupérer les données, veuillez verifier les informations de votre radio";
    return;
  }

  histories.value = currentRadio.value.song_history;
  publicity.value = currentRadio.value.publicities.filter(
    (pub) => pub.is_enable
  );

  if (streamingLink.value !== currentRadio.value.radio_flux) {
    streamingLink.value = currentRadio.value.radio_flux;

    if (isPlaying.value) {
      toggleAudio();
      toggleAudio();
    }
  }

  setBluetoothInfo();

  const url = currentRadio.value.current_track.cover;
  if (cover.value !== url) {
    cover.value = url;
    setBackgroundImage(url);
  }
};

const setBackgroundColor = (coverUrl: string): void => {
  getColorImg(coverUrl, (hotBgColor) => {
    document.body.style.backgroundColor = hotBgColor;
  });
};

const setBackgroundImage = (url: string): void => {
  document.body.style.backgroundImage = `url(${url})`;
  setBackgroundColor(url);
};

const radioStore = useRadioStore();
const route = useRoute();
const qrCodeUrl = ref(window.location.origin);
onBeforeMount(async () => {
  let radioName = route.params.radio_name as string;
  if (radioName) {
    sessionStorage.setItem("radio_name", radioName);
  } else {
    radioName = sessionStorage.getItem("radio_name") ?? "";
  }
  document.title = `${route.params.radio_name ?? radioName}`;
  radioStore.radioName = radioName;

  isDataLoading.value = true;
  await getRadioMetaData(radioName);
  const manifestLink = await buildManifest(document.title);

  setManifestHref(manifestLink);
  setFavIcon(currentRadio.value?.radio_cover ?? "");
  qrCodeUrl.value = qrCodeUrl.value + `/${radioName}`;
  isDataLoading.value = false;
});

const audioPlayer = ref<HTMLAudioElement>();
const isPlaying = ref(false);
const toggleAudio = (): void => {
  if (isPlaying.value) audioPlayer.value?.pause();
  else audioPlayer.value?.play();

  isPlaying.value = !isPlaying.value;
};

const changeVolume = (volume: number): void => {
  if (audioPlayer.value) audioPlayer.value.volume = volume;
};

const shouldDisplayQrCode = ref(false);
const shouldDisplayMenus = ref(false);
const shouldDisplayRecentTitles = ref(false);
const shouldDisplayVolume = ref(false);
const closeModal = (): void => {
  shouldDisplayQrCode.value = false;
  shouldDisplayMenus.value = false;
  shouldDisplayRecentTitles.value = false;
  shouldDisplayVolume.value = false;
  radioStore.radioErrMsg = "";
};

setInterval(() => {
  getRadioMetaData(sessionStorage.getItem("radio_name") || "");
}, 15_000);

const getCurrentRadio = computed(() => currentRadio.value);

const numberOfHistoryToDisplay = ref(1);
const changeSize = (): void => {
  if (innerWidth > 768 && innerWidth < 1024) {
    numberOfHistoryToDisplay.value = 2;
  } else if (innerWidth > 1024) {
    numberOfHistoryToDisplay.value = 3;
  } else {
    numberOfHistoryToDisplay.value = 1;
  }
};

const isRunningStandalone = (): boolean => {
  if ("standalone" in navigator) {
    return !!navigator.standalone;
  }
  return window.matchMedia("(display-mode: standalone)").matches;
};

const checkIfAppAlreadyInstalled = async (
  event: BeforeInstallPromptEvent,
  appName: string
): Promise<boolean> => {
  if (
    "getInstalledRelatedApps" in navigator &&
    navigator.getInstalledRelatedApps instanceof Function
  ) {
    const relatedApps = await navigator.getInstalledRelatedApps();
    if (!relatedApps.length) {
      event.preventDefault();
      deferredPrompt.value = event;
      isInstallationSupported.value = true;
      return true;
    }

    const psApp = relatedApps.find(
      (app: { id: string }) => app.id === `${BASE_APP_URL}${appName}`
    );

    if (psApp) {
      isInstallationSupported.value = false;
      event.preventDefault();
    }
    return false;
  }

  return false;
};

const beforeInstallationPrompt = async (
  event: BeforeInstallPromptEvent
): Promise<void> => {
  event.preventDefault();
  if (isRunningStandalone()) {
    return;
  }
  await checkIfAppAlreadyInstalled(event, radioStore.radioName);
};

onMounted(() => {
  changeSize();
  window.addEventListener("resize", changeSize);

  window.addEventListener("beforeinstallprompt", beforeInstallationPrompt);
  window.addEventListener("appinstalled", onAfterInstall);
});

onBeforeUnmount(() => {
  window.removeEventListener("resize", changeSize);
  window.removeEventListener("beforeinstallprompt", beforeInstallationPrompt);
  window.removeEventListener("appinstalled", onAfterInstall);
});
</script>
<style>
body {
  @apply bg-no-repeat bg-cover bg-fixed bg-center;

  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    sans-serif;
}
</style>

const shareRadio = async (url: string): Promise<void> => {
  if (!navigator.canShare) {
    console.warn("Fonctionnalite non disponible sur votre appareil");
    return;
  }
  try {
    await navigator.share({
      url,
    });
  } catch (error) {
    console.log(error);
  }
};

export default shareRadio;

import { ref } from "vue";

export interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed";
    platform: string;
  }>;
  prompt(): Promise<void>;
}

export const deferredPrompt = ref<BeforeInstallPromptEvent | null>();
export const isInstallationSupported = ref<boolean>(false);

export const onAfterInstall = (): void => {
  deferredPrompt.value = null;
};

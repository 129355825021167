import { createRouter, createWebHistory } from "vue-router";
import EntryPage from "../views/EntryPage.vue";
const routes = [
  {
    path: "/:radio_name",
    name: "player",
    component: EntryPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

/**
 * Color box class
 *
 * @param {Array} colorRange    [[rMin, rMax],[gMin, gMax], [bMin, bMax]] Color range
 * @param {any} total   Total pixels, imageData / 4
 * @param {any} data    Pixel data set
 */
class ColorBox {
  colorRange: unknown[];
  total: number;
  data: Uint8ClampedArray;
  volume: number;
  rank: number;
  constructor(colorRange: any[], total: number, data: Uint8ClampedArray) {
    this.colorRange = colorRange;
    this.total = total;
    this.data = data;
    this.volume =
      (colorRange[0][1] - colorRange[0][0]) *
      (colorRange[1][1] - colorRange[1][0]) *
      (colorRange[2][1] - colorRange[2][0]);
    this.rank = total * this.volume;
  }
  getColor() {
    const total = this.total;
    const data = this.data;
    let redCount = 0,
      greenCount = 0,
      blueCount = 0;

    for (let i = 0; i < total; i++) {
      redCount += data[i * 4];
      greenCount += data[i * 4 + 1];
      blueCount += data[i * 4 + 2];
    }
    return [redCount / total, greenCount / total, blueCount / total];
  }
}

const getCutSide = (colorRange: number[][]) => {
  const arr = [];
  for (let i = 0; i < 3; i++) {
    arr.push(colorRange[i][1] - colorRange[i][0]);
  }
  return arr.indexOf(Math.max(arr[0], arr[1], arr[2]));
};

const cutRange = (colorRange: number[][], colorSide: number, cutValue: any) => {
  const arr1: number[][] = [];
  const arr2: number[][] = [];
  colorRange.forEach(function (item) {
    arr1.push(item.slice());
    arr2.push(item.slice());
  });
  arr1[colorSide][1] = cutValue;
  arr2[colorSide][0] = cutValue;

  return [arr1, arr2];
};

const __quickSort = (arr: any[]): any => {
  if (arr.length <= 1) {
    return arr;
  }
  const pivotIndex = Math.floor(arr.length / 2);
  const pivot = arr.splice(pivotIndex, 1)[0];
  const left = [];
  const right = [];
  for (let i = 0; i < arr.length; i++) {
    if (arr[i].count <= pivot.count) {
      left.push(arr[i]);
    } else {
      right.push(arr[i]);
    }
  }
  return __quickSort(left).concat([pivot], __quickSort(right));
};

const getMedianColor = (
  colorCountMap: Record<string, number>,
  total: number
) => {
  const arr = [];
  for (const key in colorCountMap) {
    arr.push({
      color: parseInt(key),
      count: colorCountMap[key],
    });
  }

  const sortArr = __quickSort(arr);
  let medianCount = 0;
  const medianIndex = Math.floor(sortArr.length / 2);

  for (let i = 0; i <= medianIndex; i++) {
    medianCount += sortArr[i].count;
  }

  return {
    color: parseInt(sortArr[medianIndex].color),
    count: medianCount,
  };
};

const cutBox = (colorBox: {
  colorRange: number[][];
  total: number;
  data: Uint8ClampedArray;
}) => {
  const colorRange = colorBox.colorRange;
  const cutSide = getCutSide(colorRange);
  const colorCountMap: Record<string, number> = {};
  const total = colorBox.total;
  const data = colorBox.data;

  for (let i = 0; i < total; i++) {
    const color = data[i * 4 + cutSide];

    if (colorCountMap[color]) {
      colorCountMap[color] += 1;
    } else {
      colorCountMap[color] = 1;
    }
  }

  const medianColor = getMedianColor(colorCountMap, total);
  const cutValue = medianColor.color;
  const cutCount = medianColor.count;
  const newRange = cutRange(colorRange, cutSide, cutValue);
  const box1 = new ColorBox(newRange[0], cutCount, data.slice(0, cutCount * 4));
  const box2 = new ColorBox(
    newRange[1],
    total - cutCount,
    data.slice(cutCount * 4)
  );
  return [box1, box2];
};

const queueCut = (queue: any[], num: number) => {
  while (queue.length < num) {
    queue.sort((a: { rank: number }, b: { rank: number }) => {
      return a.rank - b.rank;
    });
    const colorBox = queue.pop();
    const result = cutBox(colorBox);
    queue = queue.concat(result);
  }
  return queue.slice(0, num);
};

const colorFilter = (colorArr: number[][], difference: number) => {
  for (let i = 0; i < colorArr.length; i++) {
    for (let j = i + 1; j < colorArr.length; j++) {
      if (
        Math.abs(colorArr[i][0] - colorArr[j][0]) < difference &&
        Math.abs(colorArr[i][1] - colorArr[j][1]) < difference &&
        Math.abs(colorArr[i][2] - colorArr[j][2]) < difference
      ) {
        colorArr.splice(j, 1);
        j--;
      }
    }
  }
  return colorArr;
};

/**
 * Extract color
 * @param colorNumber Extract maximum number of colors
 * @param img Pictures to be extracted
 * @param difference Image color filtering accuracy
 * @param callback Callback function
 */
const themeColor = (
  colorNumber: number,
  img: CanvasImageSource,
  difference: number,
  callback: (arg0: number[][]) => void
) => {
  const canvas = document.createElement("canvas") as HTMLCanvasElement;
  const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
  let width = 0;
  let height = 0;
  let imageData = null;

  canvas.width = img.width as number;
  width = canvas.width as number;
  canvas.height = img.height as number;
  height = canvas.height;

  ctx.drawImage(img, 0, 0, width, height);

  imageData = ctx.getImageData(0, 0, width, height).data;

  const total = imageData.length / 4;

  let rMin = 255,
    rMax = 0,
    gMin = 255,
    gMax = 0,
    bMin = 255,
    bMax = 0;

  for (let i = 0; i < total; i++) {
    const red = imageData[i * 4];
    const green = imageData[i * 4 + 1];
    const blue = imageData[i * 4 + 2];

    if (red < rMin) {
      rMin = red;
    }

    if (red > rMax) {
      rMax = red;
    }

    if (green < gMin) {
      gMin = green;
    }

    if (green > gMax) {
      gMax = green;
    }

    if (blue < bMin) {
      bMin = blue;
    }

    if (blue > bMax) {
      bMax = blue;
    }
  }

  const colorRange = [
    [rMin, rMax],
    [gMin, gMax],
    [bMin, bMax],
  ];
  const colorBox = new ColorBox(colorRange, total, imageData);
  const colorBoxArr = queueCut([colorBox], colorNumber);
  let colorArr = [];

  for (let j = 0; j < colorBoxArr.length; j++) {
    colorBoxArr[j].total && colorArr.push(colorBoxArr[j].getColor());
  }

  colorArr = colorFilter(colorArr, difference);

  callback(colorArr);
};

export default themeColor;

<template>
  <div class="flex items-center gap-x-1">
    <div class="cursor-pointer" @click="muteOrPlay">
      <VolumeDownIcon
        v-if="currentVolume > 0 && currentVolume < 80"
        class="w-10 h-10 fill-black"
      />
      <NoSoundIcon v-if="currentVolume === 0" class="w-10 h-10 fill-black" />
      <VolumeUpIcon v-if="currentVolume >= 80" class="w-10 h-10 fill-black" />
    </div>
    <input
      type="range"
      @change="($event) => volumeChange(($event.target as HTMLInputElement).value)"
      min="0"
      max="100"
      :value="currentVolume"
      class="h-[3px] w-32 rounded-full"
    />
  </div>
</template>
<script setup lang="ts">
import NoSoundIcon from "@/assets/NoSoundIcon.vue";
import VolumeDownIcon from "@/assets/VolumeDownIcon.vue";
import VolumeUpIcon from "@/assets/VolumeUpIcon.vue";
import { onMounted, ref } from "vue";

const emit = defineEmits(["change"]);
const props = defineProps<{
  volume: number;
}>();

const currentVolume = ref(50);
const volumeChange = (level: string): void => {
  currentVolume.value = Number(level);
  emit("change", currentVolume.value / 100);
};

onMounted(() => {
  volumeChange(`${props.volume * 100}`);
});

let previousVolume = currentVolume.value;
const muteOrPlay = (): void => {
  if (currentVolume.value) {
    previousVolume = currentVolume.value;
    volumeChange("0");
    return;
  }
  volumeChange(`${previousVolume}`);
};
</script>

import axios from "axios";
import { BASE_API_URL } from "./env";

export const buildManifest = async (name: string): Promise<string> => {
  let manifestLink = "";
  try {
    const response = await axios.get(`${BASE_API_URL}/manifest/${name}`);
    manifestLink = response.data.manifest_link;
  } catch (error) {
    console.log(error);
  }

  return manifestLink;
};

export const setManifestHref = (href: string): void => {
  let manifestLink = document.querySelector("link[rel~='manifest']");
  if (!manifestLink) {
    manifestLink = document.createElement("link");
    manifestLink.setAttribute("rel", "manifest");
    manifestLink.setAttribute("crossorigin", "use-credentials");
  }

  manifestLink?.setAttribute("href", href);
};

export const setFavIcon = (iconUrl: string) => {
  let link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.setAttribute("rel", "icon");
    link.setAttribute("type", "");
    link.setAttribute("sizes", "16x16 32x32");
    document.head.appendChild(link);
  }
  link.setAttribute("href", iconUrl);
};
